import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { RangeConfigType } from '@yldr/contract-helpers';
import { CurrencyToggler } from '../../../components-yldr/components/CurrencyToggler';
import { PriceDiffStepCounter } from '../../../ui-kit/PriceDiffStepCounter';
import { priceToTick } from '../../../utils/priceToTick';
import { convertTickDiffToPercent } from '../../../utils/convertTickDiffToPercent';
import { IEnrichedUniswapPosition } from '../../../types/uniswapTokens';
import { LOWER_PERCENT_MAX, LOWER_PERCENT_MIN, UPPER_PERCENT_MAX, UPPER_PERCENT_MIN } from './constants';
import { TConfiguredAutomationsData } from './types';
import { decompileRebalanceData } from './helpers/decompileRebalanceData';

export interface IAutoRebalancePercentProps {
  enrichedPosition: IEnrichedUniswapPosition;
  configuredAutomationsData: TConfiguredAutomationsData | undefined;
  symbolTokenA: string;
  symbolTokenB: string;
  onCurrencyToggle: () => void;
  setTriggerLower: React.Dispatch<React.SetStateAction<number>>;
  setTriggerUpper: React.Dispatch<React.SetStateAction<number>>;
  isSorted: boolean;
}

export const AutoRebalancePercent = ({
  enrichedPosition,
  configuredAutomationsData,
  symbolTokenA,
  symbolTokenB,
  onCurrencyToggle,
  setTriggerLower,
  setTriggerUpper,
  isSorted,
}: IAutoRebalancePercentProps) => {
  const [lowerPriceEnabled, setLowerPriceEnabled] = React.useState(true);
  const [upperPriceEnabled, setUpperPriceEnabled] = React.useState(true);
  const [belowCurrentPercentValue, setBelowCurrentPercentValue] = React.useState(LOWER_PERCENT_MAX.toString());
  const [aboveCurrentPercentValue, setAboveCurrentPercentValue] = React.useState(UPPER_PERCENT_MIN.toString());

  useEffect(() => {
    if (lowerPriceEnabled) {
      const triggerLower = priceToTick(
        enrichedPosition.poolPrice.plus(
          enrichedPosition.poolPrice
            .div(100)
            .multipliedBy(Number(belowCurrentPercentValue) || 0)
        ),
        enrichedPosition.token0.decimals,
        enrichedPosition.token1.decimals,
      );
      setTriggerLower(triggerLower);
    } else {
      setTriggerLower(enrichedPosition.tickLower);
    }
  }, [setTriggerLower, belowCurrentPercentValue, lowerPriceEnabled, enrichedPosition.tickCurrent]);

  useEffect(() => {
    if (upperPriceEnabled) {
      const triggerUpper = priceToTick(
        enrichedPosition.poolPrice.plus(
          enrichedPosition.poolPrice
            .div(100)
            .multipliedBy(Number(aboveCurrentPercentValue) || 0)
        ),
        enrichedPosition.token0.decimals,
        enrichedPosition.token1.decimals,
      );
      setTriggerUpper(triggerUpper);
    } else {
      setTriggerUpper(enrichedPosition.tickUpper);
    }
  }, [setTriggerUpper, aboveCurrentPercentValue, upperPriceEnabled, enrichedPosition.tickCurrent]);

  useEffect(() => {
    const rebalanceDataSaved = decompileRebalanceData(configuredAutomationsData?.rebalances[0]);
    if (
      rebalanceDataSaved.recurring.rangeConfig.rangeConfigType === RangeConfigType.TICKS &&
      rebalanceDataSaved.initialized
    ) {
      if (rebalanceDataSaved.recurring.rangeConfig.ticksDown) {
        setBelowCurrentPercentValue(
          convertTickDiffToPercent(-rebalanceDataSaved.recurring.rangeConfig.ticksDown).toString()
        );
      }
      if (rebalanceDataSaved.recurring.rangeConfig.ticksUp) {
        setAboveCurrentPercentValue(
          convertTickDiffToPercent(rebalanceDataSaved.recurring.rangeConfig.ticksUp).toString()
        );
      }
    }
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 4, mb: 1 }}>
        <Typography variant="secondary14" color="text.tertiary">
          If price goes
        </Typography>
        <CurrencyToggler
          baseCurrencySymbol={isSorted ? symbolTokenA : symbolTokenB}
          quoteCurrencySymbol={isSorted ? symbolTokenB : symbolTokenA}
          isSorted={isSorted}
          onCurrencyToggle={onCurrencyToggle}
          sx={{ flex: 'unset', width: 'unset' }}
        />
      </Box>
      <PriceDiffStepCounter
        title="Below current price by"
        symbolTokenA={isSorted ? symbolTokenA : symbolTokenB}
        symbolTokenB={isSorted ? symbolTokenB : symbolTokenA}
        value={belowCurrentPercentValue}
        minValue={LOWER_PERCENT_MIN}
        maxValue={LOWER_PERCENT_MAX}
        enabled={lowerPriceEnabled}
        setEnabled={setLowerPriceEnabled}
        onChange={setBelowCurrentPercentValue}
        isPercentage={true}
        onIncrement={() => setBelowCurrentPercentValue((
          Number(belowCurrentPercentValue) < LOWER_PERCENT_MAX
            ? Number(belowCurrentPercentValue) + 1
            : LOWER_PERCENT_MAX
        ).toString())}
        onDecrement={() => setBelowCurrentPercentValue((
          Number(belowCurrentPercentValue) > LOWER_PERCENT_MIN
            ? Number(belowCurrentPercentValue) - 1
            : LOWER_PERCENT_MIN
        ).toString())}
      />
      <PriceDiffStepCounter
        title="Above current price by"
        symbolTokenA={isSorted ? symbolTokenA : symbolTokenB}
        symbolTokenB={isSorted ? symbolTokenB : symbolTokenA}
        value={aboveCurrentPercentValue}
        minValue={UPPER_PERCENT_MIN}
        maxValue={UPPER_PERCENT_MAX}
        enabled={upperPriceEnabled}
        setEnabled={setUpperPriceEnabled}
        onChange={setAboveCurrentPercentValue}
        isPercentage={true}
        onIncrement={() => setAboveCurrentPercentValue((
          Number(aboveCurrentPercentValue) < UPPER_PERCENT_MAX
            ? Number(aboveCurrentPercentValue) + 1
            : UPPER_PERCENT_MAX
        ).toString())}
        onDecrement={() => setAboveCurrentPercentValue((
          Number(aboveCurrentPercentValue) > UPPER_PERCENT_MIN
            ? Number(aboveCurrentPercentValue) - 1
            : UPPER_PERCENT_MIN
        ).toString())}
      />
    </Box>
  );
};
