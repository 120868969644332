import { EndTriggerType, RangeConfigType, ScheduledRebalanceStructOutput } from '@yldr/contract-helpers';
import { decompileRebalanceData } from './decompileRebalanceData';
import { EGasFeeCeilingType } from '../types';
import { convertTickDiffToPercent } from '../../../../utils/convertTickDiffToPercent';
import { priceToTick } from '../../../../utils/priceToTick';
import { IEnrichedUniswapPosition } from '../../../../types/uniswapTokens';
import { getDiffDaysToTimestamp } from './getDiffDaysToTimestamp';
import { BigNumber } from 'bignumber.js';

export const detectChangedValues = (
  rebalance: ScheduledRebalanceStructOutput | undefined,
  enrichedPosition: IEnrichedUniswapPosition,
  options: {
    rangeConfigType: RangeConfigType;
    gasFeeCeilingType: EGasFeeCeilingType;
    gasFeeCeilingValue: string;
    endTriggerType: EndTriggerType;
    endTriggerValue: string;
    lowerPercent: string;
    upperPercent: string;
    triggerLower: number,
    triggerUpper: number,
  },
) => {
  const rebalanceDataSaved = decompileRebalanceData(rebalance);

  const lowerPercentSaved = convertTickDiffToPercent(-rebalanceDataSaved.newTicksDown).toFixed();
  const upperPercentSaved = convertTickDiffToPercent(rebalanceDataSaved.newTicksUp).toFixed();
  const gasFeeCeilingTypeSaved = rebalanceDataSaved.gasFeeConfig.maxUsd
    ? EGasFeeCeilingType.usd
    : EGasFeeCeilingType.percent;

  if (options.rangeConfigType !== rebalanceDataSaved.recurring.rangeConfig.rangeConfigType) return true;

  if (options.gasFeeCeilingType !== gasFeeCeilingTypeSaved) return true;

  if (gasFeeCeilingTypeSaved === EGasFeeCeilingType.usd) {
    if (options.gasFeeCeilingValue !== rebalanceDataSaved.gasFeeConfig.maxUsd.toString()) return true;
  }

  if (gasFeeCeilingTypeSaved === EGasFeeCeilingType.percent) {
    if (options.gasFeeCeilingValue !== rebalanceDataSaved.gasFeeConfig.maxPositionPercent.toString()) return true;
  }

  if (options.endTriggerType !== rebalanceDataSaved.recurring.endConfig.triggerType) return true;

  if (options.endTriggerType === EndTriggerType.COUNT) {
    if (options.endTriggerValue !== rebalanceDataSaved.recurring.endConfig.count.toString()) return true;
  }

  if (options.endTriggerType === EndTriggerType.TIMESTAMP) {
    const daysSaved = getDiffDaysToTimestamp(rebalanceDataSaved.recurring.endConfig.timestamp).toString();
    if (options.endTriggerValue !== daysSaved) return true;
  }

  if (options.lowerPercent !== lowerPercentSaved) return true;

  if (options.upperPercent !== upperPercentSaved) return true;

  if (options.rangeConfigType === RangeConfigType.TICKS) {
    const ticksDownPercentSaved = convertTickDiffToPercent(-rebalanceDataSaved.recurring.rangeConfig.ticksDown).toString();
    const ticksUpPercentSaved = convertTickDiffToPercent(rebalanceDataSaved.recurring.rangeConfig.ticksUp).toString();
    const triggerLower = priceToTick(
      enrichedPosition.poolPrice.plus(
        enrichedPosition.poolPrice.div(100).multipliedBy(ticksDownPercentSaved)
      ),
      enrichedPosition.token0.decimals,
      enrichedPosition.token1.decimals,
    );
    const triggerUpper = priceToTick(
      enrichedPosition.poolPrice.plus(
        enrichedPosition.poolPrice.div(100).multipliedBy(ticksUpPercentSaved)
      ),
      enrichedPosition.token0.decimals,
      enrichedPosition.token1.decimals,
    );
    if (options.triggerLower !== triggerLower) return true;
    if (options.triggerUpper !== triggerUpper) return true;
  }

  if (options.rangeConfigType === RangeConfigType.PRICE) {
    const belowCurrentPrice = BigNumber(rebalanceDataSaved.recurring.rangeConfig.sqrtPriceX96Down)
      .multipliedBy(rebalanceDataSaved.recurring.rangeConfig.sqrtPriceX96Down)
      .multipliedBy(BigNumber(10).pow(enrichedPosition.token0.decimals))
      .div(BigNumber(10).pow(enrichedPosition.token1.decimals))
      .div(BigNumber(2).pow(192))
      .toFixed();
    const aboveCurrentPrice = BigNumber(rebalanceDataSaved.recurring.rangeConfig.sqrtPriceX96Up)
      .multipliedBy(rebalanceDataSaved.recurring.rangeConfig.sqrtPriceX96Up)
      .multipliedBy(BigNumber(10).pow(enrichedPosition.token0.decimals))
      .div(BigNumber(10).pow(enrichedPosition.token1.decimals))
      .div(BigNumber(2).pow(192))
      .toFixed();
    const triggerLower = priceToTick(
      enrichedPosition.poolPrice.minus(belowCurrentPrice),
      enrichedPosition.token0.decimals,
      enrichedPosition.token1.decimals,
    );
    const triggerUpper = priceToTick(
      enrichedPosition.poolPrice.plus(aboveCurrentPrice),
      enrichedPosition.token0.decimals,
      enrichedPosition.token1.decimals,
    );
    if (options.triggerLower !== triggerLower) return true;
    if (options.triggerUpper !== triggerUpper) return true;
  }

  return false;
}
