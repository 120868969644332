import { SxProps, Theme } from '@mui/material';
import { ToggleButtonGroup } from '../../../components/primitives/ToggleButton/ToggleButtonGroup';
import { ToggleButton } from '../../../components/primitives/ToggleButton/ToggleButton';

export interface ICurrencyTogglerProps {
  isSorted?: boolean;
  invertDisabled?: boolean;
  onCurrencyToggle: () => void;
  baseCurrencySymbol?: string;
  quoteCurrencySymbol?: string;
  sx?: SxProps<Theme>;
}

export const CurrencyToggler = ({
  isSorted,
  invertDisabled,
  onCurrencyToggle,
  baseCurrencySymbol,
  quoteCurrencySymbol,
  sx,
}: ICurrencyTogglerProps) => (
  <ToggleButtonGroup
    color="primary"
    exclusive={true}
    value={isSorted}
    onChange={onCurrencyToggle}
    disabled={invertDisabled}
    sx={{
      height: '28px',
      flex: { xs: '1 1 auto', md: 'unset' },
      width: { xs: '100%', md: 'unset' },
      mb: { xs: 1, md: 'unset' },
      padding: '2px',
      ['>button']: {
        border: 'none !important',
      },
      ...sx,
    }}
  >
    <ToggleButton
      size="small"
      value={true}
    >
      {isSorted ? baseCurrencySymbol : quoteCurrencySymbol}
    </ToggleButton>
    <ToggleButton
      size="small"
      value={false}
    >
      {isSorted ? quoteCurrencySymbol : baseCurrencySymbol}
    </ToggleButton>
  </ToggleButtonGroup>
)
