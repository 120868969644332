import {
  RangeConfigType, ScheduledCompoundStructOutput,
  ScheduledDeleverageStructOutput,
  ScheduledRebalanceStructOutput
} from '@yldr/contract-helpers';

export enum EGasFeeCeilingType {
  percent = 'percent',
  usd = 'usd',
}

export interface IRebalanceTriggerItem {
  value: RangeConfigType;
  label: string;
}

export type TConfiguredAutomationsData = [
  ScheduledRebalanceStructOutput[],
  ScheduledDeleverageStructOutput[],
  ScheduledCompoundStructOutput[]
] & {
  rebalances: ScheduledRebalanceStructOutput[],
  deleverages: ScheduledDeleverageStructOutput[],
  compounds: ScheduledCompoundStructOutput[]
};
