import React, { useEffect, useRef } from 'react';
import { BigNumber } from 'bignumber.js';
import { Box, Typography } from '@mui/material';
import { RangeConfigType } from '@yldr/contract-helpers';
import { PriceDiffStepCounter } from '../../../ui-kit/PriceDiffStepCounter';
import { CurrencyToggler } from '../../../components-yldr/components/CurrencyToggler';
import { priceToTick } from '../../../utils/priceToTick';
import { decompileRebalanceData } from './helpers/decompileRebalanceData';
import { TConfiguredAutomationsData } from './types';
import { IEnrichedUniswapPosition } from '../../../types/uniswapTokens';

export interface IAutoRebalancePriceProps {
  enrichedPosition: IEnrichedUniswapPosition;
  configuredAutomationsData: TConfiguredAutomationsData | undefined;
  symbolTokenA: string;
  symbolTokenB: string;
  onCurrencyToggle: () => void;
  setTriggerLower: React.Dispatch<React.SetStateAction<number>>;
  setTriggerUpper: React.Dispatch<React.SetStateAction<number>>;
  isSorted: boolean;
}

export const AutoRebalancePrice = ({
  enrichedPosition,
  configuredAutomationsData,
  symbolTokenA,
  symbolTokenB,
  onCurrencyToggle,
  setTriggerLower,
  setTriggerUpper,
  isSorted,
}: IAutoRebalancePriceProps) => {
  const currentPrice = isSorted
    ? enrichedPosition.poolPrice
    : enrichedPosition.invertedPoolPrice;
  const lowerPriceDefault = BigNumber(currentPrice)
    .div(100)
    .multipliedBy(-1)
    .decimalPlaces(12);
  const upperPriceDefault = BigNumber(currentPrice)
    .div(100)
    .decimalPlaces(12);
  const [lowerPriceEnabled, setLowerPriceEnabled] = React.useState(true);
  const [upperPriceEnabled, setUpperPriceEnabled] = React.useState(true);
  const [lowerPriceDelta, setLowerPriceDelta] = React.useState(lowerPriceDefault.toFixed());
  const [upperPriceDelta, setUpperPriceDelta] = React.useState(upperPriceDefault.toFixed());
  const isSortedRef = useRef(isSorted);

  const lowerPriceDeltaMin = currentPrice
    .minus(currentPrice.div(10000))
    .multipliedBy(-1)
    .decimalPlaces(12)
    .toNumber();

  const lowerPriceDeltaMax = lowerPriceDefault
    .div(100)
    .decimalPlaces(12)
    .toNumber();

  const upperPriceDeltaMin = upperPriceDefault
    .div(100)
    .decimalPlaces(12)
    .toNumber();

  const offset = currentPrice.div(1000)
    .decimalPlaces(12)
    .toNumber();

  useEffect(() => {
    if (lowerPriceEnabled) {
      const triggerLower = priceToTick(
        enrichedPosition.poolPrice.plus(
          Number(
            isSorted
              ? lowerPriceDelta
              : enrichedPosition.poolPrice.multipliedBy(
                BigNumber(lowerPriceDelta).div(enrichedPosition.invertedPoolPrice).decimalPlaces(12)
              )
          ) || 0
        ),
        enrichedPosition.token0.decimals,
        enrichedPosition.token1.decimals,
      );
      setTriggerLower(triggerLower);
    } else {
      setTriggerLower(enrichedPosition.tickLower);
    }
  }, [
    setTriggerLower,
    lowerPriceDelta,
    lowerPriceEnabled,
    enrichedPosition.poolPrice,
    enrichedPosition.invertedPoolPrice,
  ]);

  useEffect(() => {
    if (upperPriceEnabled) {
      const triggerUpper = priceToTick(
        enrichedPosition.poolPrice.plus(
          Number(
            isSorted
              ? upperPriceDelta
              : enrichedPosition.poolPrice.multipliedBy(
                BigNumber(upperPriceDelta).div(enrichedPosition.invertedPoolPrice).decimalPlaces(12)
              )
          ) || 0
        ),
        enrichedPosition.token0.decimals,
        enrichedPosition.token1.decimals,
      );
      setTriggerUpper(triggerUpper);
    } else {
      setTriggerUpper(enrichedPosition.tickUpper);
    }
  }, [
    setTriggerUpper,
    upperPriceDelta,
    upperPriceEnabled,
    enrichedPosition.poolPrice,
    enrichedPosition.invertedPoolPrice,
  ]);

  useEffect(() => {
    const rebalanceDataSaved = decompileRebalanceData(configuredAutomationsData?.rebalances[0]);
    if (
      rebalanceDataSaved.recurring.rangeConfig.rangeConfigType === RangeConfigType.PRICE &&
      rebalanceDataSaved.initialized
    ) {
      if (rebalanceDataSaved.recurring.rangeConfig.sqrtPriceX96Down) {
        const sqrtPriceX96Down = rebalanceDataSaved.recurring.rangeConfig.sqrtPriceX96Down;
        const belowCurrentPrice = BigNumber(sqrtPriceX96Down)
          .multipliedBy(sqrtPriceX96Down)
          .multipliedBy(BigNumber(10).pow(enrichedPosition.token0.decimals))
          .div(BigNumber(10).pow(enrichedPosition.token1.decimals))
          .div(BigNumber(2).pow(192))
          .multipliedBy(-1)
          .decimalPlaces(12)
          .toFixed();
        setLowerPriceDelta(belowCurrentPrice);
      }
      if (rebalanceDataSaved.recurring.rangeConfig.sqrtPriceX96Up) {
        const sqrtPriceX96Up = rebalanceDataSaved.recurring.rangeConfig.sqrtPriceX96Up;
        const aboveCurrentPrice = BigNumber(sqrtPriceX96Up)
          .multipliedBy(sqrtPriceX96Up)
          .multipliedBy(BigNumber(10).pow(enrichedPosition.token0.decimals))
          .div(BigNumber(10).pow(enrichedPosition.token1.decimals))
          .div(BigNumber(2).pow(192))
          .decimalPlaces(12)
          .toFixed();
        setUpperPriceDelta(aboveCurrentPrice);
      }
    }
  }, []);

  useEffect(() => {
    if (isSorted !== isSortedRef.current) {
      const prevPrice = isSortedRef.current
        ? enrichedPosition.poolPrice
        : enrichedPosition.invertedPoolPrice;
      const lowerPriceRate = BigNumber(lowerPriceDelta).div(prevPrice);
      const upperPriceRate = BigNumber(upperPriceDelta).div(prevPrice);
      setLowerPriceDelta(
        currentPrice.multipliedBy(lowerPriceRate).decimalPlaces(12).toFixed()
      );
      setUpperPriceDelta(
        currentPrice.multipliedBy(upperPriceRate).decimalPlaces(12).toFixed()
      );
      isSortedRef.current = isSorted;
    }
  }, [isSorted]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 4, mb: 1 }}>
        <Typography variant="secondary14" color="text.tertiary">
          If price goes
        </Typography>
        <CurrencyToggler
          baseCurrencySymbol={isSorted ? symbolTokenA : symbolTokenB}
          quoteCurrencySymbol={isSorted ? symbolTokenB : symbolTokenA}
          isSorted={isSorted}
          onCurrencyToggle={onCurrencyToggle}
          sx={{ flex: 'unset', width: 'unset' }}
        />
      </Box>
      <PriceDiffStepCounter
        title="Below current price by"
        symbolTokenA={isSorted ? symbolTokenA : symbolTokenB}
        symbolTokenB={isSorted ? symbolTokenB : symbolTokenA}
        value={lowerPriceDelta}
        minValue={lowerPriceDeltaMin}
        maxValue={lowerPriceDeltaMax}
        enabled={lowerPriceEnabled}
        setEnabled={setLowerPriceEnabled}
        onChange={setLowerPriceDelta}
        onIncrement={() => setLowerPriceDelta((
          Number(lowerPriceDelta) + offset < lowerPriceDeltaMax
            ? Number(lowerPriceDelta) + offset
            : lowerPriceDeltaMax
        ).toString())}
        onDecrement={() => setLowerPriceDelta((
          Number(lowerPriceDelta) - offset > lowerPriceDeltaMin
            ? Number(lowerPriceDelta) - offset
            : lowerPriceDeltaMin
        ).toString())}
      />
      <PriceDiffStepCounter
        title="Above current price by"
        symbolTokenA={isSorted ? symbolTokenA : symbolTokenB}
        symbolTokenB={isSorted ? symbolTokenB : symbolTokenA}
        value={upperPriceDelta}
        minValue={upperPriceDeltaMin}
        enabled={upperPriceEnabled}
        setEnabled={setUpperPriceEnabled}
        onChange={setUpperPriceDelta}
        onDecrement={() => setUpperPriceDelta((
          Number(upperPriceDelta) - offset > upperPriceDeltaMin
            ? Number(upperPriceDelta) - offset
            : upperPriceDeltaMin
        ).toString())}
      />
    </Box>
  );
};
