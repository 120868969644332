import React from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { Box, Button, Typography } from '@mui/material';
import { NumericInput } from '../NumericInput';

export interface IPercentageStepCounterProps {
  label: string;
  description: string;
  value: string;
  minValue: number;
  maxValue: number;
  onChange: (value: string) => void;
  isPercentage?: boolean;
}

export const PercentageStepCounter = ({
  label,
  description,
  value,
  minValue,
  maxValue,
  onChange,
}: IPercentageStepCounterProps) => {
  const handlerOnIncrement = () => {
    if (!value) return;
    const newValue = Number(value) + 1;
    if (newValue >= minValue && newValue <= maxValue) {
      onChange(newValue.toString());
    }
  };

  const handlerOnDecrement = () => {
    if (!value) return;
    const newValue = Number(value) - 1;
    if (newValue >= minValue && newValue <= maxValue) {
      onChange(newValue.toString());
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 2,
      flex: 1,
      padding: 3,
      borderRadius: '8px',
      border: '1px solid var(--stroke-6, rgba(255, 255, 255, 0.06))',
      background: '#2A2A32',
    }}>
      <Box>
        <Typography variant="secondary13" color="text.secondary">{label}</Typography>
        <NumericInput
          value={value}
          minValue={minValue}
          maxValue={maxValue}
          onChange={onChange}
          isPercentage={true}
          sx={{ fontSize: '18px', ['>input']: { pb: 0.5, pt: 1 } }}
        />
        <Typography variant="main12" color="text.tertiary">
          {description}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Button
          size="small"
          onClick={handlerOnIncrement}
          sx={{
            width: '28px',
            minWidth: '28px',
            borderRadius: '6px',
            border: '1px solid rgba(255, 255, 255, 0.06)',
          }}
        >
          <AddRoundedIcon />
        </Button>
        <Button
          size="small"
          onClick={handlerOnDecrement}
          sx={{
            width: '28px',
            minWidth: '28px',
            borderRadius: '6px',
            border: '1px solid rgba(255, 255, 255, 0.06)',
          }}
        >
          <RemoveRoundedIcon />
        </Button>
      </Box>
    </Box>
  );
};
