import { IRebalanceTriggerItem } from './types';
import { RangeConfigType } from '@yldr/contract-helpers';

export const REBALANCE_TRIGGER_LIST: IRebalanceTriggerItem[] = [
  {
    value: RangeConfigType.RANGE,
    label: 'Out of range'
  },
  {
    value: RangeConfigType.PRICE,
    label: 'Price'
  },
  {
    value: RangeConfigType.TICKS,
    label: 'Percent'
  }
];

export const REBALANCE_MIN_VALUE = 1;
export const DEFAULT_REBALANCE_COUNTS = 10;
export const DEFAULT_REBALANCE_DAYS = 90;

export const LOWER_PERCENT_MIN = -99;
export const LOWER_PERCENT_MAX = -0.01;
export const UPPER_PERCENT_MIN = 0.01;
export const UPPER_PERCENT_MAX = 99;

export const PROTOCOL_FEE = 0.0015;
