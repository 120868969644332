import { Currency, Token } from '@uniswap/sdk-core';

import { ComputedReserveData } from '../../../hooks/app-data-provider/useAppDataProvider';
import { useProtocolDataContext } from '../../../hooks/useProtocolDataContext';

export function useCurrency(token?: ComputedReserveData): Currency | undefined {
  const { currentChainId: chainId } = useProtocolDataContext();
  return token
    ? new Token(
      chainId, token.underlyingAsset, token.decimals, token.symbol, token.name
    )
    : undefined;
}


