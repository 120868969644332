import { ToggleButtonGroup as MuiToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';

export const ToggleButtonGroup = ({ children, sx, ...rest }: ToggleButtonGroupProps) => (
  <MuiToggleButtonGroup
    {...rest}
    sx={{
      flex: { xs: '1 1 auto', md: 'unset' },
      width: { xs: '100%', md: 'unset' },
      padding: '3px',
      gap: 1,
      borderRadius: '6px',
      border: '1px solid rgba(255, 255, 255, 0.06)',
      backgroundColor: '#26262B',
      ...sx,
    }}
  >
    {children}
  </MuiToggleButtonGroup>
);
