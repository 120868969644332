import {
  EndConfigStruct,
  RangeConfigStruct,
  ScheduledRebalanceStructOutput
} from '@yldr/contract-helpers';
import { BigNumber } from 'bignumber.js';
import { USD_DECIMALS } from '@yldr/math-utils';

export const decompileRebalanceData = (data?: ScheduledRebalanceStructOutput): {
  initialized: boolean;
  triggerLower: number;
  triggerUpper: number;
  newTicksDown: number;
  newTicksUp: number;
  recurring: {
    rangeConfig: RangeConfigStruct;
    endConfig: EndConfigStruct;
    active: boolean;
  };
  gasFeeConfig: {
    maxUsd: number;
    maxPositionPercent: number;
  };
} => {
  if (!data) {
    return {
      triggerLower: 0,
      triggerUpper: 0,
      newTicksDown: 0,
      newTicksUp: 0,
      recurring: {
        rangeConfig: {
          rangeConfigType: 0,
          ticksDown: 0,
          ticksUp: 0,
          sqrtPriceX96Down: '0',
          sqrtPriceX96Up: '0',
        },
        endConfig: {
          triggerType: 0,
          count: 0,
          timestamp: 0,
        },
        active: false,
      },
      gasFeeConfig: {
        maxUsd: 0,
        maxPositionPercent: 0,
      },
      initialized: false,
    }
  }
  return {
    triggerLower: data.triggerLower,
    triggerUpper: data.triggerUpper,
    newTicksDown: data.newTicksDown,
    newTicksUp: data.newTicksUp,
    recurring: {
      rangeConfig: {
        rangeConfigType: data.recurring.rangeConfig[0],
        ticksDown: data.recurring.rangeConfig[1],
        ticksUp: data.recurring.rangeConfig[2],
        sqrtPriceX96Down: data.recurring.rangeConfig[3].toString(),
        sqrtPriceX96Up: data.recurring.rangeConfig[4].toString(),
      },
      endConfig: {
        triggerType: data.recurring.endConfig[0],
        count: data.recurring.endConfig[1].toNumber(),
        timestamp: data.recurring.endConfig[2].toNumber(),
      },
      active: data.recurring.active,
    },
    gasFeeConfig: {
      maxUsd: BigNumber(data.gasFeeConfig.maxUsd.toString())
        .div(BigNumber(10).pow(USD_DECIMALS))
        .toNumber(),
      maxPositionPercent: data.gasFeeConfig.maxPositionPercent.toNumber() / 100,
    },
    initialized: data.initialized,
  }
};
