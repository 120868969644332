import { BigNumber } from 'bignumber.js';
import { UNISWAP_TICK } from '../ui-config/constants';

export const convertTickDiffToPercent = (tickDiff?: number) => {
  if (!tickDiff) return BigNumber(0);
  const result = BigNumber(UNISWAP_TICK).pow(tickDiff);
  return result.isGreaterThanOrEqualTo(1)
    ? result.minus(1).multipliedBy(100).decimalPlaces(2)
    : BigNumber(1).minus(result).multipliedBy(-100).decimalPlaces(2);
};
