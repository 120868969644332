import React, { useRef } from 'react';
import { InputBaseProps } from '@mui/material/InputBase/InputBase';
import { InputBase, SxProps, Theme } from '@mui/material';
import { validateValue } from '../../utils/validateValue';

export interface IPercentageInputProps extends Omit<InputBaseProps, 'onChange'> {
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  minValue?: number;
  maxValue?: number;
  sx?: SxProps<Theme>;
  isPercentage?: boolean;
  decimalPlaces?: number;
}

export const NumericInput = ({ minValue, maxValue, value, isPercentage, onChange, onBlur, decimalPlaces, sx, ...rest }: IPercentageInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
      .replace(/[^\d.,-]/g, '')
      .replace(',', '.');

    if (!validateValue(value.replace('-', ''), decimalPlaces)) return;

    if (maxValue && maxValue > 0) {
      const correctedValue = Number(value) > maxValue ? maxValue.toString() : value;
      onChange(correctedValue);
    } else if (minValue && minValue < 0) {
      const correctedValue = Number(value) < minValue ? minValue.toString() : value;
      onChange(correctedValue);
    } else {
      onChange(value);
    }

    setTimeout(() => {
      if (inputRef.current) {
        const cursorPosition = value.length;
        inputRef.current.selectionStart = cursorPosition;
        inputRef.current.selectionEnd = cursorPosition;
      }
    }, 0);
  };

  const handleOnBlur = () => {
    if (minValue && minValue > 0) {
      const correctedValue = Number(value) < minValue || isNaN(parseFloat(value))
        ? minValue.toString()
        : value;
      onChange(correctedValue);
    }
    if (maxValue && maxValue < 0) {
      const correctedValue = Number(value) > maxValue || isNaN(parseFloat(value))
        ? maxValue.toString()
        : value;
      onChange(correctedValue);
    }
    onBlur?.();
  };

  const handleOnFocus = () => {
    setTimeout(() => {
      if (inputRef.current) {
        const cursorPosition = value.toString().length;
        inputRef.current.selectionStart = cursorPosition;
        inputRef.current.selectionEnd = cursorPosition;
      }
    }, 0);
  };

  return (
    <InputBase
      inputRef={inputRef}
      value={`${value}${isPercentage ? '%' : ''}`}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      sx={{
        fontSize: '15px',
        ...sx
      }}
      {...rest}
    />
  );
};
